import {motion} from "framer-motion";
import React, {useRef} from "react";

import {IItemSize} from "./index";

interface VerticalProps {
    letter: string;
    size: IItemSize;
    fontSize: number;
    onStart?: () => void;
    onCompleted?: () => void;
}

const chars = ["9", "8", "7", "6", "5", "4", "3", "2", "1", "0"];
const charsIndex = new Map<string, number>(
    Array(chars.length).fill(true).map((_, index) => [chars[index], index])
)


function Vertical(props: VerticalProps & React.HTMLAttributes<HTMLDivElement>) {
    const ref = useRef(null);
    let {letter, size, fontSize, onStart, onCompleted} = props;
    const charIndex = charsIndex.get(letter);


    if (charIndex === undefined) {
        return (
            <div
                className={"text-column"}
                style={{
                    height: size.height,
                }}
            >
                <div
                    className={"content-text"}
                    style={{
                        width: `${size.width}px`,
                        height: `${size.height}px`,
                        fontSize: `${fontSize}px`,
                        lineHeight: `${fontSize}px`,
                        fontWeight: "900"
                    }}
                >{letter}</div>
            </div>
        );
    }

    const y = `${(-charIndex / (chars.length)) * 100}%`;

    // // generate random y array
    // const _yArray = Array(chars.length).fill(true).map((_, index) => {
    //     return `${(-index / (chars.length)) * 100}%`;
    // });
    //
    // //shuffle y array
    // _yArray.sort(() => Math.random() - 0.5);
    // const yArray = _yArray.splice(0, 3);
    // yArray.push(y);

    return (
        <motion.div
            ref={ref}
            initial={{y, opacity: 0}}
            animate={{y: y, opacity: 1, transition: {duration: 1}}}
            exit={{y, opacity: 0}}
            transition={{ease: "easeOut"}}
            className={"text-column"}
            style={{
                height: chars.length * size.height,
            }}
        >

            {
                chars.map((char) => {
                    return (
                        <motion.div
                            key={`${char}-${charIndex}`}
                            initial={{opacity: 0.8}}
                            animate={{opacity: 1}}
                            whileHover={{scale: 1.3}}
                            className={"content-text"}
                            style={{
                                width: `${size.width}px`,
                                height: `${size.height}px`,
                                fontSize: `${fontSize}px`,
                                lineHeight: `${fontSize}px`,
                                fontWeight: "900"
                            }}
                        >
                            {char}
                        </motion.div>
                    )
                })
            }
        </motion.div>
    )
}

export default Vertical
import './index.scss'

import {Disclosure} from '@headlessui/react'
import clsx from "clsx";
import {Field, Form, Formik, FormikHelpers} from "formik";
import React, {useState} from 'react'
import {CSVLink} from "react-csv";
import {FaGear, FaUpload} from "react-icons/fa6";
import {toast} from "react-toastify";
import {object, string} from "yup";

import bgPrize from '../../assets/images/bg-prize.png';
import bgResult from '../../assets/images/bg-result.png';
import eventLogo from '../../assets/images/event-logo.png';
import logo from '../../assets/images/logo.svg';
import prize from '../../assets/images/prize.png';
import FlipTool from "../../components/FlipTool";


const $ = (s: string): HTMLElement => document.querySelector(s) as HTMLElement;

interface User {
    phoneX?: string,
    phone: string,
    name: string
}

function Home(props: any) {
    let [isOpen, setIsOpen] = useState(false)
    let [isOpenSettings, setIsOpenSettings] = useState(false)
    const [winner, setWinner] = React.useState<User | null>(null);
    const [winners, setWinners] = React.useState<User[]>([]);
    const [phone, setPhone] = React.useState<string>("9999999999");
    const [users, setUsers] = React.useState<User[]>([]);

    const [settings, setSettings] = React.useState<any>({
        prize: {
            name: "01 GALAXY WATCH",
            image: prize,
            background: bgPrize,
            backgroundResult: bgResult,
            quantity: 3
        },
        event: {
            name: "Giải tháng 11",
        }
    });

    const limit = settings.prize.quantity;

    function onShuffle() {
        if (winners.length >= limit) {
            setIsOpen(true);
            return;
        }
        if (users.length === 0) {
            toast.error('Vui lòng nhập danh sách khách hàng!', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        let _winner: User = users[Math.floor(Math.random() * users.length)];
        let prize = _winner.phone.slice(0, 3) + "XXXX" + _winner.phone.slice(7, 10);
        _winner.phoneX = prize;
        setWinner(_winner);
        setPhone(prize);

        // Remove winner from users
        setUsers(users.filter((user) => user.phone !== _winner.phone));

        setTimeout(() => {
            setIsOpen(true);
        }, 1000);
    }

    function closeModal() {
        if (winners.length >= limit) {
            setIsOpen(false);
            return;
        }
        setIsOpen(false);
        setPhone('9999999999');
    }

    const convertCSVToJson = (csvData: string) => {
        const lines = csvData.split("\n");
        const headers = lines[0].split(",");
        const result = [];

        for (let i = 1; i < lines.length; i++) {
            const obj: any = {};
            const currentLine = lines[i].split(",");

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j].trim()] = currentLine[j].trim();
            }
            result.push(obj);
        }

        return result;
    };

    const handleCSVInputChange = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const csvData = e.target.result;
            const jsonData = convertCSVToJson(csvData);
            setUsers(jsonData);

            toast.success(`Import danh sách ${jsonData.length} khách hàng thành công!`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        };

        reader.readAsText(file);
    };

    const handleImageInputChange = (event: any) => {
        const file = event.target.files[0];
        setSettings({...settings, prize: {...settings.prize, image: URL.createObjectURL(file)}});
    };

    function saveWiners() {
        setWinners([...winners, winner as User]);
        toast.success(`Lưu kết quả thành công!`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        closeModal();
    }

    return (
        <main className="container mx-auto flex flex-col h-screen">
            <div className={"absolute top-1/2 right-0"}>
                <button type={"button"} className={"text-gray-500 px-2 py-2 bg-white uppercase rounded-tl-md rounded-bl-md"} onClick={e => setIsOpenSettings(true)}><FaGear/></button>
            </div>
            <Disclosure as="nav" className="flex flex-row justify-between items-center my-3 mx-3 md:mx-0">
                {({open}) => (
                    <>
                        <img
                            className="h-8 md:h-16 w-auto"
                            src={logo}
                            alt="Toto"
                        />

                        <img
                            className="h-8 md:h-16 w-auto"
                            src={eventLogo}
                            alt="Toto"
                        />
                    </>
                )}
            </Disclosure>
            <div className={"flex-1 flex flex-col mt-10 lg:mt-40"}>
                <div className={"flex flex-col lg:flex-row h-3/4"}>
                    <div className={"w-full lg:w-6/12 relative"}>
                        <div className={"w-full lg:w-6/12 mx-auto bg-gradient-1 text-white text-center p-2"}>
                            <h3 className={"text-2xl font-semibold"}>{settings.event.name}</h3>
                            <h3 className={"text-2xl font-semibold"}>Số lượng: {settings.prize.quantity} giải</h3>
                        </div>
                        <div className={"text-center relative -mt-20"}>
                            <div className={"mix-blend-screen"}>
                                <img src={settings.prize.background} alt={"Toto"} className={"ms-3"}/>
                            </div>
                            <img src={settings.prize.image} alt={"Toto"} className={"absolute h-32 lg:h-48 mix-blend-normal top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"}/>
                        </div>
                        <div className={"w-full lg:w-6/12 bg-gradient-2 text-white text-center -mt-16 p-3 mx-auto"}>
                            <h1 className={"text-xl lg:text-3xl font-bold"}>{settings.prize.name}</h1>
                        </div>
                    </div>
                    <div className={"w-full lg:w-6/12 mt-16 lg:mt-0"}>
                        <div className={"flex flex-row justify-center px-3 lg:px-0"}>
                            <img src={bgResult} alt={"Toto"} className={"h32 lg:h-40"}/>
                        </div>
                        <h1 className={"mt-5 text-2xl lg:text-3xl font-bold text-white text-center"}>{winners.length >= limit ? '' : `QUAY LẦN ${winners.length + 1}`}</h1>
                        <div className={"lottery-flip separate text-white mt-10 flex flex-row justify-center"}>
                            <FlipTool text={phone} fontSize={50} size={{width: 60, height: 60}} gap={{x: 5, y: 5}}/>
                        </div>
                        <div className={"flex flex-row justify-center mt-5"}>
                            <button className={"text-white text-xl lg:text-2xl font-bold px-20 py-2 bg-gradient-3 uppercase"} onClick={e => onShuffle()}>{winners.length >= limit ? "TỔNG HỢP" : "QUAY SỐ"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx("settings absolute top-0 right-0 w-96 h-screen bg-white flex flex-col", isOpenSettings ? null : 'hidden')}>
                <div className={"settings-title flex items-center justify-between p-4 md:p-5 rounded-t bg-gradient-4"}>
                    <h3 className="flex-1 text-xl font-semibold text-center text-white dark:text-white">Settings</h3>
                    <button type="button" className="text-white bg-transparent hover:text-gray-200 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setIsOpenSettings(false)}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className={"settings-body py-5 px-3"}>
                    <div className="relative mb-3">
                        <input type="text"
                               className="px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" "
                               id={"eventName"}
                               name={"eventName"}
                               value={settings.event.name}
                               onChange={(e) => setSettings({...settings, event: {...settings.event, name: e.target.value}})}
                        />
                        <label htmlFor="eventName"
                               className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Tên sự kiện:
                        </label>
                    </div>
                    <div className="relative mb-3">
                        <input type="text"
                               className="px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" "
                               id={"prizeName"}
                               name={"prizeName"}
                               value={settings.prize.name}
                               onChange={(e) => setSettings({...settings, prize: {...settings.prize, name: e.target.value}})}
                        />
                        <label htmlFor="prizeName"
                               className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Tên giải thưởng:
                        </label>
                    </div>
                    <div className="relative mb-3">
                        <input type="number"
                               className="px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" "
                               id={"prizeQuantity"}
                               name={"prizeQuantity"}
                               value={settings.prize.quantity}
                               onChange={(e) => setSettings({...settings, prize: {...settings.prize, quantity: e.target.value}})}
                        />
                        <label htmlFor="prizeQuantity"
                               className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Số lượng giải thưởng:
                        </label>
                    </div>
                    <div className="relative mb-3">
                        <input type="text"
                               className="ps-2.5 pe-12 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" "
                               id={"prizeImage"}
                               name={"prizeImage"}
                               value={settings.prize.image}
                               onChange={(e) => setSettings({...settings, prize: {...settings.prize, image: e.target.value}})}
                        />
                        <label htmlFor="prizeImage"
                               className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Hình ảnh giải thưởng:
                        </label>
                        <input type="file" id={"prizeImageUpload"} accept="image/*" className={"hidden"} onChange={handleImageInputChange}/>
                        <button type={"button"} className={"absolute right-1 top-1/2 transform -translate-y-1/2 px-3 py-3 active:bg-gray-100 rounded-md"} onClick={() => $('#prizeImageUpload').click()}><FaUpload/></button>
                    </div>
                    <hr className={"mb-3"}/>
                    <div className="relative mb-3">
                        <input type="file"
                               accept={".csv"}
                               className="hidden"
                               placeholder=" "
                               id={"userInput"}
                               name={"userInput"}
                               onChange={handleCSVInputChange}
                        />
                        <button type={"button"} className={"text-white bg-gradient-3 px-3 py-2 rounded-md"} onClick={e => $('#userInput').click()}>Import danh sách khách hàng</button>
                    </div>
                </div>
            </div>
            <div id="default-modal" tabIndex={-1} aria-hidden="true" className={clsx("modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full bg-white/50", !isOpen ? 'hidden' : null)}>
                <div className="relative p-4 w-full max-w-6xl max-h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 rounded-t bg-gradient-4">
                            <h3 className="flex-1 text-xl font-semibold text-center text-white dark:text-white">
                                KẾT QUẢ QUAY SỐ MAY MẮN
                            </h3>
                            <button type="button" className="text-white bg-transparent hover:text-gray-200 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal"
                                    onClick={() => closeModal()}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4 modal-content text-white">
                            <div className={"flex flex-col lg:flex-row h-3/4"}>
                                <div className={"w-full lg:w-6/12 relative"}>
                                    <div className={"w-full mx-auto bg-gradient-1 text-white text-center p-2"}>
                                        <h3 className={"text-2xl font-semibold"}>{settings.event.name}</h3>
                                        <h3 className={"text-2xl font-semibold"}>Số lượng: {settings.prize.quantity} giải</h3>
                                    </div>
                                    <div className={"text-center relative -mt-10"}>
                                        <div className={"mix-blend-screen"}>
                                            <img src={settings.prize.background} alt={"Toto"} className={"ms-3"}/>
                                        </div>
                                        <img src={settings.prize.image} alt={"Toto"} className={"absolute h-40 mix-blend-normal top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"}/>
                                    </div>
                                    <div className={"w-full bg-gradient-2 text-white text-center -mt-10 p-3 mx-auto"}>
                                        <h1 className={"text-xl font-bold"}>{settings.prize.name}</h1>
                                    </div>
                                </div>
                                <div className={"w-full lg:w-6/12 mt-16 lg:mt-0 relative"}>
                                    <div className={"flex flex-row justify-center"}>
                                        <img src={bgResult} alt={"Toto"} className={"h-40"}/>
                                    </div>
                                    <div className={"prize-box rounded overflow-clip mt-12"}>
                                        <div className={"bg-gradient-4 text-white text-center text-xl font-bold py-3"}>
                                            CHÚC MỪNG KHÁCH HÀNG
                                        </div>
                                        <div className={"prize-box-body text-gray-500 py-4"}>
                                            {
                                                winners.length >= limit ? (

                                                    <div className={"flex flex-col justify-center items-center"}>
                                                        {
                                                            winners.map((winner, index) => (
                                                                <div key={index}>
                                                                    <h1 className={"text-4xl text-center text-gradient-1 uppercase font-bold leading-relaxed"}>{winner?.name}</h1>
                                                                    <h3 className={"text-xl text-center"}>Số điện thoại: {winner?.phoneX}</h3>
                                                                </div>
                                                            ))
                                                        }
                                                        <CSVLink data={winners} className={"mt-5 text-white text-2xl font-bold px-20 py-2 bg-gradient-3 uppercase"} filename={"prize.csv"}>Save</CSVLink>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <h1 className={"text-4xl text-center text-gradient-1 uppercase font-bold leading-relaxed"}>{winner?.name}</h1>
                                                        <h3 className={"text-xl text-center"}>Số điện thoại: {winner?.phoneX}</h3>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <button className={clsx("text-white text-sm font-bold px-4 py-1 bg-gradient-3 uppercase absolute right-0 bottom-0",winners.length >= limit ? 'hidden': null)} onClick={e => saveWiners()}>Lưu kết quả</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Home

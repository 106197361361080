import './index.scss'

import {AnimatePresence, LayoutGroup, motion} from "framer-motion"
import React, {useRef} from 'react'

import Vertical from "./vertical";


export interface IFlipToolProps {
    text: string;
    size: IItemSize;
    gap: IItemGap;
    fontSize: number;
    onAnimationCompleted?: (text:string) => void;
}

export interface IItemSize {
    width: number;
    height: number;
}

export interface IItemGap {
    x: number;
    y: number;
}

function FlipTool(props: IFlipToolProps & React.HTMLAttributes<HTMLDivElement>) {
    let {text, size, gap, fontSize, onAnimationCompleted} = props;


    const ref = useRef<HTMLDivElement>(null);
    const cache = new Map<number, boolean>();

    const textArray = String(text).split("");

    const totalWidth = textArray.length * size.width + (textArray.length - 1) * gap.x;

    fontSize = fontSize > size.height ? size.height : fontSize;

    return (
        <motion.div
            ref={ref}
            className={"flip-tool-container"}
            initial={{
                width: totalWidth,
            }}
            animate={{
                width: totalWidth,
            }}
            transition={{ease: "easeOut"}}
            style={{
                columnGap: `${gap.x}px`,
                rowGap: `${gap.y}px`,
            }}
        >
            <AnimatePresence initial={true}>
                <LayoutGroup>
                    {
                        textArray.map((letter, index) => {
                            const key = `${textArray.length - index}`;
                            return (
                                <motion.div
                                    key={key}
                                    layoutId={key}
                                    animate={{width: size.width, height: size.height, opacity: 1, animationDuration: "3s"}}
                                    initial={{width: 0, height: 0, opacity: 0}}
                                    exit={{opacity: 0}}
                                    transition={{ease: "easeOut"}}
                                    className={"text-box"}
                                >
                                    <Vertical letter={letter} size={size} fontSize={fontSize} onStart={() => cache.set(index, false)}/>
                                </motion.div>
                            )
                        })
                    }
                </LayoutGroup>
            </AnimatePresence>
        </motion.div>
    )
}


export default FlipTool
